import WebFont from "webfontloader";

const form = document.querySelector(".front-form");
const formMessage = document.querySelector(".front-message");

WebFont.load({
  typekit: {
    id: "vje0ohg",
  },
});

const displayMessage = (txt, error = false) => {
  formMessage.innerText = txt;
  formMessage.classList.toggle("error", error);
  formMessage.classList.remove("hide");

  setTimeout(() => {
    formMessage.classList.add("hide");
  }, 2500);
};

form.addEventListener("submit", (e) => {
  e.preventDefault();

  fetch(`/api/test`, {
    method: "POST",
    body: JSON.stringify({ password: e.target.password.value }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (!data.success || !data.public_keys || !data.ids) {
        throw new Error("error");
      }

      console.log(data);

      const initialEl = document.querySelector(".initial");

      const anim = initialEl.animate(
        [
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
        ],
        {
          duration: 1000,
          iterations: 1,
          delay: 500,
          fill: "forwards",
        }
      );

      anim.onfinish = () => {
        initialEl.remove();
      };

      const tableEl = document.querySelector(".table");
      tableEl.innerHTML = `<iframe src='https://prod.teamgantt.com/gantt/schedule/?ids=${data.ids}&public_keys=${data.public_keys}' frameborder='0'></iframe>`;

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          tableEl.classList.add("show");
        });
      });
    })
    .catch(() => {
      displayMessage("No project found");
    });
});

requestAnimationFrame(() => {
  requestAnimationFrame(() => {
    document.body.classList.add("transition");
  });
});
